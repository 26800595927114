import {graphql, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {contentfulImagePropTypes, contentfulMarkdownRemarkPropTypes} from '../../shared-prop-types.js'
import {StyledFooter, StyledExternalLinks, StyledSiteCredits} from './Footer.StyledComponents.js'

const Footer = (props) => {
  const {content} = props

  const currentYear = new Date().getFullYear()
  // declare new `contentSiteCredits` constant and replace {currentYear} in the Markdown-to-HTML string
  // note: don't overwrite props.content.siteCredits since that can cause PropTypes/re-rendering issues
  const contentSiteCredits = content.siteCredits.childMarkdownRemark.html.replace('{currentYear}', currentYear)

  return (
    <StyledFooter>
      <StyledExternalLinks>
        <ul>
          {content.externalLinks.map(externalLink => (
            <li key={externalLink.name}>
              <a
                href={externalLink.href}
                rel="noreferrer"
                target="_blank"
              >
                <figure>
                  <img alt="" src={`https:${externalLink.image.file.url}`}/>
                  <figcaption>{externalLink.name}</figcaption>
                </figure>
              </a>
            </li>
          ))}
        </ul>
      </StyledExternalLinks>
      <StyledSiteCredits dangerouslySetInnerHTML={{__html: contentSiteCredits}}/>
    </StyledFooter>
  )
}

Footer.propTypes = {
  content: PropTypes.shape({
    externalLinks: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        image: PropTypes.shape(contentfulImagePropTypes).isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    siteCredits: PropTypes.shape(contentfulMarkdownRemarkPropTypes).isRequired,
  }).isRequired,
}

const FooterWithContent = () => (
  <StaticQuery
    // to test queries, refer to the dev server terminal output for the GraphQL URL
    query={graphql`
      query ContentfulComponentFooterQuery {
        contentfulComponentFooter {
          externalLinks {
            href
            image {
              file {
                url
              }
            }
            name
          }
          siteCredits {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => <Footer content={data.contentfulComponentFooter}/>}
  />
)

export default FooterWithContent
