import styled from 'styled-components'
import {colors} from '../../config/colors.js'
import {zIndex} from '../../config/z-index.js'

export const StyledHeader = styled.header({
  width: '100%',
  padding: '1rem',
  backgroundColor: colors['black-#000'],
  color: colors['white-#ddd'],
  position: 'fixed',
  // refer to /config/z-index.js for why z-index needs to be set on the header
  zIndex: zIndex.styledHeader,

  '.header__inner-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '120rem',
    margin: '0 auto',
  },
})

export const StyledLogo = styled.figure({
  a: {
    display: 'block',
    lineHeight: 0,
  },

  img: {
    height: '5rem',
    width: '5rem',
  },
})

const hamburgerMenuSharedStyles = {
  transitionProperty: 'transform',
  display: 'block',
  height: '0.4rem',
  width: '4rem',
  borderRadius: '0.4rem',
  backgroundColor: colors['white-#ddd'],
  position: 'absolute',
}

export const StyledHamburgerMenuButton = styled.button({
  transitionDuration: '150ms',
  transitionProperty: 'opacity, filter',
  transitionTimingFunction: 'linear',
  padding: '0.1rem 0.5rem',
  border: 0,
  backgroundColor: colors['black-#000'],
  color: 'inherit',
  lineHeight: '1',
  '&.hamburger-menu--opened': {
    '.hamburger-menu__inner': {
      transform: 'rotate(45deg)',
      transitionDelay: '120ms',
      transitionDuration: '75ms',
      transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      '&:before': {
        top: 0,
        opacity: 0,
        transition: 'top 75ms ease, opacity 75ms 120ms ease',
      },
      '&:after': {
        bottom: 0,
        transform: 'rotate(-90deg)',
        transition: 'bottom 75ms ease, transform 75ms 120ms cubic-bezier(0.215, 0.61, 0.355, 1)',
      },
    },
  },

  '.hamburger-menu__outer': {
    display: 'block',
    height: '2.4rem',
    width: '4rem',
    position: 'relative',
  },

  '.hamburger-menu__inner': {
    ...hamburgerMenuSharedStyles,
    transitionDuration: '75ms',
    transitionTimingFunction: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    top: '50%',
    '&:before': {
      ...hamburgerMenuSharedStyles,
      // CSS-in-JS `content` property requires empty double quote string to render pseudoelements properly
      // https://github.com/styled-components/styled-components/issues/2197/#issuecomment-437698730
      content: '""',
      transition: 'top 75ms 120ms ease, opacity 75ms ease',
      top: '-1rem',
    },
    '&:after': {
      ...hamburgerMenuSharedStyles,
      content: '""',
      transition: 'bottom 75ms 120ms ease, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
      bottom: '-1rem',
    },
  },

  '.hamburger-menu__label': {
    display: 'block',
    marginTop: '1rem',
    fontSize: '1.4rem',
  },
})

const navLiBorder = `0.1rem solid ${colors['white-#ddd']}`

export const StyledNav = styled.nav(props => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  // ensure the nav height accounts for the header
  height: `calc(100% - ${props.headerHeightPx})`,
  width: '100%',
  backgroundColor: colors['white-#fff'],
  position: 'fixed',
  // ensure the nav does not overlay the header
  top: props.headerHeightPx,
  left: 0,
  right: 0,
  textAlign: 'center',

  ul: {
    width: '100%',
    padding: '0 2rem',
    listStyleType: 'none',
  },

  li: {
    maxWidth: '50rem',
    borderTop: navLiBorder,
    margin: '0 auto',
    '&:last-of-type': {
      borderBottom: navLiBorder,
    },
  },

  a: {
    display: 'block',
    padding: '0.5rem',
    color: colors['black-#000'],
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: colors['black-#000'],
      color: colors['white-#ddd'],
      textDecoration: 'underline',
    },
  },
}))
