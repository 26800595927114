import PropTypes from 'prop-types'
import React from 'react'
import {Helmet as ReactHelmet} from 'react-helmet'
import styled from 'styled-components'
import {MobileNavOpen} from '../../context/mobile-nav-open.js'
import Footer from '../Footer/Footer.jsx'
import Header from '../Header/Header.jsx'
import GlobalStyle from './GlobalStyle.js'

const Page = (props) => {
  // define header height state here because it's needed by many components
  // (Header, StyledNav, StyledMain, StyledHeroSection, etc.)
  const [headerHeightPx, setHeaderHeightPx] = React.useState('')

  const PageChildren = React.Children.map(props.children, (child) => {
    // only set the header height as a prop on the child component that matches this specific ID
    switch (child.props.componentID) {
      case 'Homepage__StyledHeroSection': return (
        React.cloneElement(child, {
          headerHeightPxState: {headerHeightPx}
        })
      )
      default: return child
    }
  })

  const [mobileNavOpen, setMobileNavOpen] = React.useState(false)
  // toggle the nav opened/closed by taking the previous state value and flipping it
  const toggleMobileNavOpen = () => setMobileNavOpen(previousMobileNavOpen => !previousMobileNavOpen)

  return (
    <>
      <ReactHelmet htmlAttributes={{lang: 'en'}}/>
      <GlobalStyle/>
      <MobileNavOpen.Provider value={{mobileNavOpen, toggleMobileNavOpen}}>
        <Header headerHeightPxState={{headerHeightPx, setHeaderHeightPx}}/>
        <StyledMain headerHeightPxState={{headerHeightPx}}>
          {PageChildren}
        </StyledMain>
        <Footer/>
      </MobileNavOpen.Provider>
    </>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledMain = styled.main(props => ({
  paddingTop: props.headerHeightPxState.headerHeightPx,
}))

export default Page
