import {graphql, Link, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {MobileNavOpen} from '../../context/mobile-nav-open.js'
import {contentfulImagePropTypes} from '../../shared-prop-types.js'
import {StyledHamburgerMenuButton, StyledHeader, StyledLogo, StyledNav} from './Header.StyledComponents.js'

const Header = (props) => {
  const {content, sitewideContent} = props
  const {headerHeightPx, setHeaderHeightPx} = props.headerHeightPxState

  const headerRef = React.useRef(null)

  React.useEffect(() => {
    // use getBoundingClientRect().height because it provides an exact decimal value
    setHeaderHeightPx(headerRef.current.getBoundingClientRect().height + 'px')
  }, [setHeaderHeightPx])

  return (
    <StyledHeader ref={headerRef}>
      <div className="header__inner-container">
        <StyledLogo>
          <Link to="/">
            <img alt={sitewideContent.logo.description} src={`https:${sitewideContent.logo.file.url}`}/>
          </Link>
        </StyledLogo>
        {/**
         * IMPORTANT NOTE: when testing in Firefox's mobile/responsive design mode make sure that the
         * 'enable touch simulation' feature is disabled, otherwise the button may be clicked _and_ tapped
         * during testing; this can trigger toggleMobileNavOpen() twice, which will then update the
         * mobileNavOpen state twice in rapid succession and result in a confused and/or sad developer
        */}
        <MobileNavOpen.Consumer>
          {({mobileNavOpen, toggleMobileNavOpen}) => (
            <>
              <StyledHamburgerMenuButton
                className={!mobileNavOpen ? null : 'hamburger-menu--opened'}
                onClick={toggleMobileNavOpen}
              >
                <span className="hamburger-menu__outer">
                  <span className="hamburger-menu__inner"/>
                </span>
                <span className="hamburger-menu__label">Menu</span>
              </StyledHamburgerMenuButton>
              {!mobileNavOpen
                ? null
                : (
                  <StyledNav headerHeightPx={headerHeightPx}>
                    <ul>
                      {content.internalLinks.map(internalLink => (
                        <li key={internalLink.name}>
                          <Link to={internalLink.href}>{internalLink.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </StyledNav>
                )
              }
            </>
          )}
        </MobileNavOpen.Consumer>
      </div>
    </StyledHeader>
  )
}

Header.propTypes = {
  content: PropTypes.shape({
    internalLinks: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  headerHeightPxState: PropTypes.shape({
    headerHeightPx: PropTypes.string.isRequired,
    setHeaderHeightPx: PropTypes.func.isRequired,
  }).isRequired,
  sitewideContent: PropTypes.shape({
    logo: PropTypes.shape(contentfulImagePropTypes).isRequired,
  }).isRequired,
}

const HeaderWithContent = (props) => (
  <StaticQuery
    /**
     * to test queries, refer to the dev server terminal output for the GraphQL URL
     *
     * note how this query pulls in data from two different content types:
     * `contentfulComponentHeader` and `contentfulSitewideMetadataSeo`
    */
    query={graphql`
      query ContentfulComponentHeaderQuery {
        contentfulComponentHeader {
          internalLinks {
            href
            name
          }
        }
        contentfulSitewideMetadataSeo {
          logo {
            description
            file {
              url
            }
          }
        }
      }
    `}
    render={data => (
      <Header
        content={data.contentfulComponentHeader}
        headerHeightPxState={props.headerHeightPxState}
        sitewideContent={data.contentfulSitewideMetadataSeo}
      />
    )}
  />
)

export default HeaderWithContent
