import PropTypes from 'prop-types'

export const contentfulImagePropTypes = {
  // use `description` as the image's alt text; note that it can be an empty string if the image
  // is purely decorative (i.e. provides no useful accessibility information for screen readers)
  description: PropTypes.string,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
}

export const contentfulMarkdownRemarkPropTypes = {
  childMarkdownRemark: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
}

export const contentfulMetadataSeoPropTypes = {
  canonicalURL: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
