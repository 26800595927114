const colors = {
  'black-#000-with-shadow': 'rgba(0, 0, 0, 0.7)',
  'black-#000': '#000',
  'grey-#404040': '#404040',
  'transparent': 'transparent',
  'white-#ddd': '#ddd',
  'white-#fff': '#fff',
}

export {colors}
