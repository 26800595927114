import styled from 'styled-components'
import {colors} from '../../config/colors.js'

export const StyledFooter = styled.footer({
  padding: '2rem',
  backgroundColor: colors['black-#000'],
  color: colors['white-#ddd'],
  fontSize: '1.2rem',
  textAlign: 'center',
})

export const StyledExternalLinks = styled.div({
  ul: {
    display: 'flex',
    justifyContent: 'space-around',
    listStyleType: 'none',
    maxWidth: '28rem',
    paddingBottom: '2rem',
    margin: '0 auto',
  },

  li: {
    maxWidth: '5.5rem',
  },

  a: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  img: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
})

export const StyledSiteCredits = styled.div({
  a: {
    color: 'inherit',
  }
})
